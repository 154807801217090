const writing = [
  {
    emoji: '🍭',
    title: 'The JavaScript Event Loop Beast',
    link:
      'https://linruolynn.notion.site/The-JavaScript-Event-Loop-Beast-f00d623ba07641b6a6a9def2e7429f98', 
  },
  {
    emoji: '🚀',
    title: 'Brief Intro to GraphQL and Apollo Client',
    link:
      'https://linruolynn.notion.site/Brief-Intro-to-GraphQL-and-Apollo-Client-e490d88802f7449898affd27c0f6d97c',
  },
  {
    emoji: '⚛️',
    title: 'PureComponents, ShallowCompare, and React.memo()',
    link:
      'https://linruolynn.notion.site/PureComponents-ShallowCompare-and-React-memo-cd28fb23e25a453c9499b4ab90acb910',
  },
]

export default writing

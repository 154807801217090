import {
  Divider as ReactDivider,
  VerticalDivider as ReactVerticalDivider,
} from '@react-md/divider'
import styled from 'styled-components'

import { LIGHT_GREY } from 'common/colors'

const Divider = styled(ReactDivider)`
  display: block;
  border: 0;
  border-top: 1px solid ${LIGHT_GREY};
  padding: 0;
  opacity: 0.8;
`

export const VerticalDivider = styled(ReactVerticalDivider)`
  opacity: 0.8;
`
export default Divider

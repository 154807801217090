export const PRIMARY = '#202C39'

export const PALE_ORANGE = '#FFFAF7'
export const TANGERINE = '#F29559'

// grey
export const LIGHT_GREY = '#E5E5E5'
export const PALE_YELLOW_GREY = '#DFDFDE'
export const GREY = '#5D5D5D'
export const DARK_GREY = '#37352f'

export const BLACK = '#000000'

import styled from 'styled-components'

interface Props {
  backgroundColor: string
}

const IconWrapper = styled.div<Props>`
  border-radius: 50%;
  background-color: ${({ backgroundColor }) => backgroundColor};
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  opacity: 0.7;
  transition: opacity 0.3s
  :hover {
    opacity: 1;
  }
`

export default IconWrapper

import React from 'react'
import { GitHub, Linkedin, Mail, Twitter } from 'react-feather'

import Stack, { Gutter } from 'components/Stack'
import IconWrapper from 'components/IconWrapper'

const ICON_COLOR = '#fff'

const GITHUB = 'https://github.com/linnnruoo'
const LINKEDIN = 'https://linkedin.com/in/linruolynn'
const MAIL = 'mailto:linruo.b@u.nus.edu'
const TWITTER = 'https://twitter.com/LLinruo'

interface Props {
  isMobileScreen: boolean
}

const SocialMediaGroup: React.FC<Props> = ({ isMobileScreen }) => {
  const openNewTab = (link: string) => window.open(link)

  return (
    <Stack gutter={isMobileScreen ? Gutter.SMALL : Gutter.REGULAR}>
      <IconWrapper backgroundColor="#0971BD" onClick={() => openNewTab(MAIL)}>
        <Mail color={ICON_COLOR} />
      </IconWrapper>
      <IconWrapper backgroundColor="#161514" onClick={() => openNewTab(GITHUB)}>
        <GitHub color={ICON_COLOR} />
      </IconWrapper>
      <IconWrapper
        backgroundColor="#0077B5"
        onClick={() => openNewTab(LINKEDIN)}
      >
        <Linkedin color={ICON_COLOR} />
      </IconWrapper>
      <IconWrapper
        backgroundColor="#55ACEE"
        onClick={() => openNewTab(TWITTER)}
      >
        <Twitter color={ICON_COLOR} />
      </IconWrapper>
    </Stack>
  )
}

export default SocialMediaGroup

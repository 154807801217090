import React from 'react'

import Stack, { Gutter } from 'components/Stack'
import Text from 'components/Text'
import { DARK_GREY } from 'common/colors'
import styled from 'styled-components'

interface Props {
  emoji: string // 🤔
  title: string
  link: string
}

const NotionPost: React.FC<Props> = ({ emoji, title, link }) => {
  const [browserWidth, changeBrowserWidth] = React.useState(window.screen.width)
  React.useEffect(() => {
    const handleResize = () => {
      const browserWidth = window.screen.width
      changeBrowserWidth(browserWidth)
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <PostContainer alignItems="center" gutter={Gutter.MINI}>
      <Emoji center>{emoji}</Emoji>
      <TitleContainer>
        <Title
          fontSize={16}
          fontWeight={500}
          secondaryFont
          color={DARK_GREY}
          onClick={() => window.open(link)}
          defaultFont
          screenWidth={browserWidth}
        >
          {title}
        </Title>
      </TitleContainer>
    </PostContainer>
  )
}

const TitleContainer = styled.div`
  padding-top: 3px;
  padding-bottom: 3px;
  cursor: pointer;
`

const Title = styled(Text)<{ screenWidth: number }>`
  ${({ screenWidth }) => screenWidth <= 620 && `width: ${screenWidth - 60}px`}
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-bottom: 1px solid rgba(55, 53, 47, 0.16);
`

const Emoji = styled(Stack)`
  height: 22px;
  width: 22px;
  margin-left: 2px;
  margin-right: 2px;
  cursor: default;
`

const PostContainer = styled(Stack)`
  padding: 2px;
  width: 100%;
  transition: background 120ms ease-in 0s;
  :hover {
    background-color: rgba(55, 53, 47, 0.08);
  }
`

export default NotionPost

import React from 'react'

import Text from 'components/Text'
import { BLACK } from 'common/colors'
import Stack, { Gutter } from 'components/Stack'
import Divider from 'components/Divider'

interface Props {
  content: string
}

const SectionHeader: React.FC<Props> = ({ content }) => {
  return (
    <Stack vertical gutter={Gutter.MINI}>
      <Text fontSize={12} color={BLACK}>
        {content}
      </Text>
      <Divider />
    </Stack>
  )
}

export default SectionHeader

import React from 'react'

// import { GREY } from 'common/colors'
import Stack, { Gutter } from 'components/Stack'
import Text from 'components/Text'

import SocialMediaGroup from './SocialMediaGroup'

interface Props {
  isMobileScreen: boolean
}

const Introduction: React.FC<Props> = ({ isMobileScreen }) => {
  return (
    <Stack
      vertical
      alignItems={isMobileScreen ? 'center' : 'flex-start'}
      justifyContent="center"
      gutter={Gutter.REGULAR}
    >
      <Stack vertical gutter={Gutter.EXTRA_SMALL}>
        <Text secondaryFont fontSize={26} fontWeight="bold">
          Hi, I'm Lynn
        </Text>
        <Text fontSize={14}>
          I'm a software engineer from Singapore, currently working in the Bay Area.
          I'm always excited to meet new people! If you would like to have a chat, feel free to drop me an email or take a look
          here:
        </Text>
      </Stack>
      <Stack center gutter={isMobileScreen ? Gutter.SMALL : Gutter.REGULAR}>
        <SocialMediaGroup isMobileScreen={isMobileScreen} />
      </Stack>
    </Stack>
  )
}

export default Introduction

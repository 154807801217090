import styled from 'styled-components'

import { PRIMARY } from 'common/colors'

interface Props {
  primary?: boolean
  secondaryFont?: boolean
  defaultFont?: boolean
  color?: string
  fontWeight?: string | number
  fontSize?: number
}

const Text = styled.div<Props>`
  overflow-x: auto;
  font-family: ${({ secondaryFont }) =>
      secondaryFont ? 'Helvetica Neue' : 'Montserrat'},
    sans-serif;
  color: ${({ color, primary }) => getColor(color, primary)};
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight}`};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize}px`};
  ${({ defaultFont }) =>
    defaultFont &&
    'font-family:  -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";'}
`

const getColor = (color?: string, primary?: boolean) => {
  if (color) {
    return color
  }
  if (primary) {
    return PRIMARY
  }
  return PRIMARY
}

export default Text

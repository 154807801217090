import React from 'react'

import writing from 'common/writing'
import Stack from 'components/Stack'
import NotionPost from 'components/NotionPost'

const Blog: React.FC = () => (
  <Stack vertical>
    {writing.map((post, index) => (
      <NotionPost
        key={index}
        emoji={post.emoji}
        title={post.title}
        link={post.link}
      />
    ))}
  </Stack>
)

export default Blog

import styled from 'styled-components'

const Layout = styled.div<{}>`
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 90px;
  margin-bottom: 90px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 620px) {
    margin-top: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (min-width: 1152px) {
    width: 960px;
  }
`

export default Layout

import React from 'react'

import profile from 'assets/profile.jpg'
import Stack, { Gutter } from 'components/Stack'
import Avatar from 'components/Avatar'
import SectionHeader from 'components/SectionHeader'

import Introduction from './Introduction'
// import Experience from './Experience'
import Blog from './Blog'

const Home: React.FC<{}> = () => {
  const initialBrowserWidth = window.screen.width
  const [isMobileScreen, toggleMobileView] = React.useState(
    initialBrowserWidth <= 620,
  )
  React.useEffect(() => {
    const handleResize = () => {
      const browserWidth = window.screen.width
      if (browserWidth <= 620) {
        toggleMobileView(true)
      } else {
        toggleMobileView(false)
      }
    }
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <Stack vertical gutter={Gutter.MEDIUM}>
      <Stack
        vertical={isMobileScreen}
        center={isMobileScreen}
        gutter={Gutter.EXTRA_LARGE}
      >
        <Avatar src={profile} />
        <Introduction isMobileScreen={isMobileScreen} />
      </Stack>
      {/* <Stack vertical gutter={Gutter.REGULAR}>
        <SectionHeader content="Here are some places I've had the priviledge to work at..." />
        <Experience isMobileScreen={isMobileScreen} />
      </Stack> */}
      <Stack vertical gutter={Gutter.REGULAR}>
        <SectionHeader content="Here are some of my brain dumps..." />
        <Blog />
      </Stack>
    </Stack>
  )
}

export default Home

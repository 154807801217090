import styled from 'styled-components'

const Avatar = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
  max-width: 250px;
  object-fit: cover;
`

export default Avatar
